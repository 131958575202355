const pageSpecific = ($, isMobile) => {
	handleSaucePage($);
	handleReservationsPage($, isMobile);

	function handleSaucePage($) {
		if ($('body').hasClass('page-template-template-sauces')) {
			$('.location__title').on('click', function () {
				$(this).parent().children().toggleClass('active');
			});
		}
	}

	function handleReservationsPage($, isMobile) {
		if ($('body').hasClass('page-template-template-reservations')) {
			initSlickSlider($, isMobile);
			$(window).on('resize', function () {
				initSlickSlider($, isMobile);
			});
		}
	}

	function initSlickSlider($, isMobile) {
		if ($(window).width() <= 1024) {
			if (
				!$('.locations__bottom--container').hasClass(
					'slick-initialized'
				)
			) {
				$('.locations__bottom--container').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
				});
			}
		} else {
			if (
				$('.locations__bottom--container').hasClass('slick-initialized')
			) {
				$('.locations__bottom--container').slick('unslick');
			}
		}
	}
};

export default pageSpecific;
