const menu = ($, isMobile) => {
	// Sub Menu Handler
	$('.menu-item-has-children').on('click', function (e) {
		e.stopPropagation();

		// Close other submenus
		$('.menu-item-has-children').each(function () {
			if (this !== e.currentTarget) {
				$(this).children('.sub-menu').removeClass('open');
			}
		});

		// Open submenu
		openSubMenu($(this));

		// Add mobile back button if necessary
		if (
			$(window).width() < 1021 &&
			!$(this).find('.mobile-nav-back').length
		) {
			const backButton = $(
				'<li><a href="javascript:void(0)" class="mobile-nav-back">BACK</a><li>'
			);
			backButton.on('click', mobileCloseSubNav);
			$(this).find('.sub-menu.open').prepend(backButton);
		}
	});

	// Prevent sub-menu click propagation
	$('.sub-menu').on('click', function (e) {
		e.stopPropagation();
	});

	// Mobile Menu Handler
	$('.hamburger').on('click', function () {
		resetMenu();
		$(this).toggleClass('open');
		$('.nav').toggleClass('open');
	});

	// Close menu on sub-menu item click
	$('.sub-menu')
		.find('a')
		.on('click', function (e) {
			$('.hamburger').toggleClass('open');
			$('.nav').toggleClass('open');
			e.stopPropagation();
		});

	// Ensure last menu item styles are set
	if ($('#menu-main-menu')) {
		$('#menu-main-menu')
			.find('.menu-item-has-children')
			.last()
			.find('ul')
			.css({ width: '100px', left: 0 });
	}

	// Function to close the submenu
	function mobileCloseSubNav(e) {
		e.preventDefault();
		$('.mobile-nav-back').remove(); // Clean up back buttons
		$(this).closest('.sub-menu').removeClass('open'); // Close the submenu
	}

	// Function to reset all menus
	function resetMenu() {
		$('.menu-item-has-children').find('a.open').removeClass('open');
		$('.sub-menu').removeClass('open');
		$('.mobile-nav-back').remove(); // Clean up back buttons
	}

	// Function to open a submenu
	function openSubMenu(parent) {
		parent.children('a').first().addClass('open');
		parent.children('.sub-menu').addClass('open');
	}
};

export default menu;
