const footer = ($) => {
	$('.footer-global .toggle').on('click', function () {
		$(this).toggleClass('open');
		if ($(this).hasClass('open')) {
			$('.footer-global--locations--container').slideDown();
		} else {
			$('.footer-global--locations--container').hide();
		}
	});
};

export default footer;
