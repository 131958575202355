import 'slick-carousel';
const menuSliders = ($, isMobile) => {
	var left_arrow = templateUrl + '/images/button-left-arrow.svg';
	var right_arrow = templateUrl + '/images/button-right-arrow.svg';
	var left_arrow_mobile = templateUrl + '/images/mobile-left-arrow.svg';
	var right_arrow_mobile = templateUrl + '/images/mobile-right-arrow.svg';
	// Register Menu Sliders
	if ($('.menu__nav')) {
		// Menu Nav
		if ($('.top-menu-nav').length) {
			$('.top-menu-nav').on('click', function (e) {
				$(this).toggleClass('top-menu-nav--opened');
				e.stopPropagation();
			});

			$(document).click(function () {
				$('.top-menu-nav').removeClass('top-menu-nav--opened');
			});
		}

		$('.menu__nav').on('click', function () {
			$('.menu__nav').toggleClass('open');
			$('.menu__nav').attr('aria-expanded', function (i, attr) {
				return attr == 'false' ? 'true' : 'false';
			});
			$('.menu__link').toggleClass('open');
		});

		// Menu Slide Small
		if ($('.menu-slide--small').length) {
			$('.menu-slide--small').slick({
				autoplay: false,
				slidesToShow: 4,
				variableWidth: true,
				slidesToScroll: 4,
				infinite: false,
				prevArrow: `<div class='slick-prev'><img class='a-right control-c next' src='${left_arrow}'></div>`,
				nextArrow: `<div class='slick-next'><span><img class='a-right control-c next' src='${right_arrow}'></span></div>`,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
				],
			});
		}

		// Menu Slide Large
		if (isMobile) {
			if ($('.menu-slide--no-image').length) {
				$('.menu-slide--no-image').slick({
					autoplay: false,
					slidesToShow: 2,
					variableWidth: true,
					slidesToScroll: 1,
					infinite: false,
					slickSetOption: {
						refresh: true,
					},
					prevArrow: `<div class='slick-prev'><img class='a-right control-c next' src='${left_arrow}'></div>`,
					nextArrow: `<div class='slick-next'><span><img class='a-right control-c next' src='${right_arrow}'></span></div>`,
				});
			}
		}

		// Menu Submenu
		if ($('.menu-submenu').length) {
			$('.menu-submenu').slick({
				autoplay: false,
				slidesToShow: 1,
				infinite: false,
				focusOnSelect: true,
				variableWidth: true,
				prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${left_arrow_mobile}'></div>`,
				nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${right_arrow_mobile}'></div>`,
			});

			$('.menu-submenu').on(
				'beforeChange',
				function (event, slick, currentSlide, nextSlide) {
					var currentContent = $('.menu__data--actived').children(
						'.menu-section'
					)[nextSlide];
					$('html, body').animate({
						scrollTop: $(currentContent).offset().top - 220,
					});
				}
			);

			var eleHeight = $('.menu__left').offset().top;

			if (isMobile) {
				$(document).scroll(function () {
					var scrollHeight = $(document).scrollTop();

					if (eleHeight - scrollHeight - 123 <= 0) {
						$('.menu__left').addClass('menu__left--fixed');
						$('.menu__right').addClass('menu__right--top-adjust');
					} else {
						$('.menu__left').removeClass('menu__left--fixed');
						$('.menu__right').removeClass(
							'menu__right--top-adjust'
						);
					}
				});
			}
		}

		// Menu Content
		if ($('.menu__content ').hasClass('mobile')) {
			$('.sticky').css('top', '40px');
		}

		// Menu Item
		if (!$('.menu__item--actived').length) {
			$('.menu__item').first().addClass('menu__item--actived');
			$('.menu-submenu').first().addClass('menu-submenu--actived');
			$('.menu__data').removeClass('menu__data--actived');
			$('.menu__item').first().click();
			$('.menu__data')
				.first()
				.addClass('menu__data--actived')
				.attr('aria-hidden', 'false');
		}

		// Menu Link
		$('.menu__link').on('click', function () {
			const curSlug = $(this).data('slug');

			if (curSlug === 'breakfast-and-brunch') {
				$('.menu__right').append(
					'<div class="menu__right--breakfast"><img src="https://tastybistro.ca/wp-content/uploads/2024/06/TASTY-BRUCH-622x1024.jpg"/></div>'
				);
			} else if ($('.menu__right--breakfast').length) {
				$('.menu__right--breakfast').remove();
			}

			$('.menu__data').removeClass('menu__data--actived');
			$('.menu__data').attr('aria-hidden', 'true');

			$(`.menu__data[data-title=${curSlug}]`)
				.addClass('menu__data--actived')
				.attr('aria-hidden', 'false');
			$('.menu__item').removeClass('menu__item--actived');
			$(this).parent('.menu__item').addClass('menu__item--actived');

			if ($('.menu-submenu').length) {
				$('.menu-submenu').removeClass('menu-submenu--actived');
				$(`.menu-submenu[data-parent=${curSlug}]`).addClass(
					'menu-submenu--actived'
				);
			}

			$('html, body').animate(
				{
					scrollTop: $('.menu__data--actived').offset().top - 204,
				},
				'smooth'
			);

			setTimeout(function () {
				$('.menu-slide--small').slick('resize');
				$('.menu-slide--large').slick('refresh');
			}, 100);

			$('.menu-slide--no-image').slick('resize');
			$('.menu-slide--no-image').slick('setPosition');
		});

		// Menu Slide Large
		if ($('.menu-slide--large').length) {
			$('.menu-slide--large').slick({
				autoplay: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: false,
				prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${left_arrow}'></div>`,
				nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${right_arrow}'></div>`,
			});
		}
	}
};

export default menuSliders;
