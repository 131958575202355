const privateDining = ($) => {
	const location = document.querySelector('#field_10_10');

	location.addEventListener('change', () => {
		const form = document.querySelector('.form');
		form.classList.remove('initial');
	});
};

export default privateDining;
