// Components
import {
	popupBox,
	footer,
	homeSliders,
	menu,
	pageSpecific,
	menuSliders,
	privateDining,
} from './components';

// Libraries
import $ from 'jquery';

class App {
	constructor(page, initialized) {
		this.page = page;
		this.initialized = initialized;
		this.isMobile = window.matchMedia('(max-width: 768px)').matches;

		if (this.initialized == false) {
			if (document.readyState === 'loading') {
				document.addEventListener('DOMContentLoaded', this.init());
			} else {
				this.init();
			}
		}
	}

	init() {
		this.initialized = true;
		footer($);
		homeSliders($, this.isMobile);
		menu($, this.isMobile);
		pageSpecific($, this.isMobile);
		popupBox();
		AOS.refresh();

		switch (true) {
			case this.page.classList.contains('page-template-template-menu'):
				menuSliders($, this.isMobile);
				break;

			case this.page.classList.contains(
				'page-template-template-private-dining'
			):
				privateDining($);
				break;
		}
	}
}

new App(document.querySelector('body'), false);
