import 'slick-carousel';
const homeSliders = ($, isMobile) => {
	var arrow1 = templateUrl + '/images/Rectangle-39.png';
	var arrow2 = templateUrl + '/images/Rectangle-37.png';
	// Register Homepage Sliders
	if ($('body').hasClass('home')) {
		$('#slider-home [data-slick]').on(
			'init',
			function (event, slick, currentSlide, nextSlide) {
				const textContainer = document
					.querySelector('.slick-active .slider-home__text')
					.getBoundingClientRect().height;
				const nextArrow = document.querySelector('.slick-next-mobile');
				if (nextArrow) {
					nextArrow.style.top = `${textContainer - 50}px`;
				}
			}
		);

		$('#slider-home [data-slick]').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${arrow1}'></div>`,
			nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${arrow2}'></div><div class='slick-next-mobile'><img class='a-right control-c next' src='${arrow2}'></div>`,
		});

		$('#slider-home [data-slick]').on(
			'afterChange',
			function (event, slick, currentSlide, nextSlide) {
				const textContainer = document
					.querySelector('.slick-active .slider-home__text')
					.getBoundingClientRect().height;
				const nextArrow = document.querySelector('.slick-next-mobile');

				if (nextArrow) {
					nextArrow.style.top = `${textContainer - 50}px`;
				}
			}
		);

		$('#cta-features [data-slick]').slick({
			centerMode: true,
			centerPadding: '0 20px',
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			infinite: true,
			dots: false,
			prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${arrow1}'></div>`,
			nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${arrow2}'></</div>`,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 641,
					settings: 'unslick',
				},
			],
		});
		// Re-initialize slider on changes
		$(window).on('load resize orientationchange', function () {
			if ($('#cta-features [data-slick]').hasClass('slick-initialized')) {
				$('#cta-features [data-slick]').slick('unslick');
			}
			$('#cta-features [data-slick]').slick({
				centerMode: true,
				centerPadding: '0 20px',
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				infinite: true,
				dots: false,
				prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${arrow1}'></div>`,
				nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${arrow2}'></</div>`,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 641,
						settings: 'unslick',
					},
				],
			});
		});

		// Initial text animation load
		$('[data-slick-index]').addClass('active');
	}
};

export default homeSliders;
